<template>
  <b-card>
    <div>
      <b-row class="p-1">
        <div v-if="!showSearch">
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-success"
            class="btn-icon rounded-circle"
            @click="showSearch = true"
            title="Pesquisa"
            v-b-tooltip.hover.v-success
          >
            <feather-icon icon="SearchIcon" />
          </b-button>
        </div>
        <div v-else>
          <b-button
            v-ripple.400="'rgba(40, 199, 111, 0.15)'"
            variant="flat-danger"
            class="btn-icon rounded-circle"
            @click="showSearch = false"
            title="Fechar"
            v-b-tooltip.hover.v-danger
          >
            <feather-icon icon="XIcon" />
          </b-button>
        </div>
        <b-col v-show="showSearch">
          <b-form-input
            placeholder="Pesquisar"
            debounce="800"
            type="search"
          />
        </b-col>
      </b-row>

      <b-table-simple
        striped
        stacked="md"
        noDataText="Você ainda não possui clientes cadastrados."
        class="mt-2 responsive text-center"
        id="tableSerasa"
        :item="fetchSerasa"
        :per-page="perPage"
        :current-page="currentPage"
      >
        <b-thead>
          <b-th> Data </b-th>
          <b-th> Gerada por </b-th>
          <b-th> Quantidade </b-th>
          <b-th> Download</b-th>
        </b-thead>
        <b-tbody>
          <b-tr :key="indextr" v-for="(tr, indextr) in serasas">
            <b-td> {{ tr.created_at }} </b-td>
            <b-td> {{ tr.user.name }} </b-td>
            <b-td> {{ tr.amount }} </b-td>
            <b-td>
              <b-button
                v-ripple.400="'rgba(40, 199, 111, 0.15)'"
                variant="flat-info"
                class="btn-icon rounded-circle"
              >
                <feather-icon icon="DownloadIcon" />
              </b-button>
            </b-td>
          </b-tr>
        </b-tbody>
      </b-table-simple>
      <b-pagination
        @change="handlePageChange"
        v-model="currentPage"
        :per-page="perPage"
        :total-rows="totalRows"
        aria-controls="tableSerasa"
        class="mt-1"
      />
    </div>
  </b-card>
</template>

<script>
import Ripple from "vue-ripple-directive";
import { mapActions, mapState } from "vuex";
import { BButton } from "bootstrap-vue";
// import vSelect from "vue-select";

export default {
  components: {
    BButton,
    // vSelect,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      perPage: 10,
      currentPage: 1,
      totalRows: null,

      showSearch: false,
    };
  },
  computed: {
    ...mapState("serasas", ["serasas", "pagination"])
  },
  methods: {
    fetchSerasa(paginated = true, page = 1) {
      this.isLoading = true;
      this.$store
        .dispatch("serasas/fetchSerasa", {
          paginated: paginated,
          page: page,
          generic: this.search,
          perPage: this.perPage,
        })

        .then((response) => {
          this.totalRows = response.total;
        })
        .catch((err) => {
          console.error(err);
        })
        .finally(() => {
          this.isLoading = false;
        });
    },
    handlePageChange(value) {
      this.fetchSerasa(true, value);
    },
  },
  created() {
    this.fetchSerasa();
  },
};
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
</style>
